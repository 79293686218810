<template>
	<div>
		<!-- Image -->
		<div v-if="imageUrl != null">
			<ion-img :src="imageUrl" />
		</div>

		<div class="text-sm text-capitalize padding-top-md padding-right-md padding-left-md">
			{{post.classified.category}} > {{post.classified.sub_category}}
		</div>
		<div>
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="title" placeholder="Enter title"></ion-input>
			</ion-item>
		</div>

		<div v-if="post.classified.category == 'for-sale'">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="make" placeholder="Enter make"></ion-input>
			</ion-item>
		</div>
		<div v-if="post.classified.category == 'for-sale'">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="model" placeholder="Enter model"></ion-input>
			</ion-item>
		</div>

		<div>
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="price" placeholder="Enter price"></ion-input>
			</ion-item>
		</div>


		<div style="padding-right:20px;">
			<TextWrapper 
				:store="store.state"
				input-type="textarea"
				:post-body="post.body"
				:is-mentions="true" 
				:is-hashtags="true" 
				:has-button="false" 
				:set-delimeter="null"
				@input-data="getInputData" />
		</div>

		<div class="ion-padding">
			<ion-button v-if="imageUrl === null" slot="start" size="small" color="light" @click="takePicture()">
				<ion-icon :icon="camera"></ion-icon>
			</ion-button>
		</div>
	</div>
</template>

<script>
import { toastController, loadingController, IonImg, IonButton, IonIcon, IonInput, IonItem } from '@ionic/vue';
import { camera, trash } from 'ionicons/icons';
import { defineComponent, ref, computed, onMounted } from 'vue';
import apiClient from '../services/api';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import TextWrapper from './TextWrapper.vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent( {
	components: { TextWrapper, IonImg, IonButton, IonIcon, IonInput, IonItem },
	name: 'ManageClassified',
	props: ['post', 'update'],
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const title = ref(null);
		const make = ref(null);
		const model = ref(null);
		const price = ref(null);
		const bodyText = ref(null);
		const imageUrl = ref(null);
		const linkData = ref([]);
		const linkDescription = ref(null);
		const linkTitle = ref(null);
		const linkImage = ref(null);
		const url = ref(null);

		onMounted(() => {
			if(props.post) {
				title.value = props.post.title
				if(props.post.filename) {
					imageUrl.value = props.post.filename
				}
				if(props.post.classified) {
					price.value = props.post.classified.price
				}
				if(props.post.product) {
					make.value = props.post.product.make
					model.value = props.post.product.model
				}
			}
		})

		function getInputData(e) {
			if(e) {
				bodyText.value = e.text
				linkData.value = e.linkData
			}
		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function updatePost() {
			presentLoading()
			apiClient.post('/api/update/post', 
				{ 
					id: props.post.id,
					title: title.value,
					body: bodyText.value, 
					status: 1, 
					post_type: 'classified' ,
					image: imageUrl.value,
					genres: null,
					make: make.value,
					model: model.value,
					price: price.value,
					url: url.value,
					link_image: linkImage.value,
					link_title: linkTitle.value,
					link_description: linkDescription.value,
					external: 1,
					
					//mentions: mentions.value,
					//hashtags: hashtags.value       
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
					toastMessage.value = 'Your post has been updated.'
					store.commit('setFeedTrigger', true);
					// Clear text wrapper
					store.commit('setGenericTrigger', true)
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					//isError.value = true
					//errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in to update your profile.'
				}
				openToast()
			}).catch(error => {
				console.log(error)
			});  
		}

		return { store, authUser, router, bodyText, linkData, linkTitle, linkDescription, linkImage, url, camera, trash, updatePost, takePicture, getInputData, imageUrl, make, model, price, title }
	},
	watch: {
		bodyText: function() {
			if(this.bodyText === null || this.bodyText == '') {
				this.linkData = []
			}
		},
		linkData: {
			handler() {
				if(this.linkData) {
					this.linkTitle = this.linkData.title
					this.linkImage = this.linkData.cover_image 
					this.linkDescription = this.linkData.description 
					this.url = this.linkData.url
				}
			},
			deep: true
		},
		update: function() {
			if(this.update === true) {
				this.updatePost()
				this.$emit('reset-update', false)
			}
		}
	}
});
</script>