<template>
	<div>
		<div>
			<ion-segment scrollable value="date" v-model="view">
				<ion-segment-button value="date">
					<ion-label>Date</ion-label>
				</ion-segment-button>
				<ion-segment-button value="time">
					<ion-label>Time</ion-label>
				</ion-segment-button>
				<ion-segment-button value="venue">
					<ion-label>Venue</ion-label>
				</ion-segment-button>
				<ion-segment-button value="general">
					<ion-label>General</ion-label>
				</ion-segment-button>
				<ion-segment-button value="images">
					<ion-label>Images</ion-label>
				</ion-segment-button>
				<ion-segment-button value="artists">
					<ion-label>Artists</ion-label>
				</ion-segment-button>
				<ion-segment-button value="tickets">
					<ion-label>Tickets</ion-label>
				</ion-segment-button>
				<ion-segment-button value="genres">
					<ion-label>Genres</ion-label>
				</ion-segment-button>
				<ion-segment-button value="booking">
					<ion-label>Booking</ion-label>
				</ion-segment-button>
			</ion-segment>
		</div>

		{{venue}}

		<!-- 
		Date
		-->
		<div v-if="view == 'date'">
			<div class="padding-top-md padding-right-md">
				<div class="padding-left-md">
					<strong>Date</strong>
				</div>
			
				<ion-item>
					<div tabindex="0"></div>
					<ion-label>Concert Date</ion-label>
					<ion-datetime value="2021-01-19" placeholder="Select Date" v-model="concertDate"></ion-datetime>
				</ion-item>
			</div>
		</div>
		<!-- 
		Time
		-->
		<div v-if="view == 'time'">
			<div class="padding-top-md padding-right-md">
				<div class="padding-left-md">
					<strong>Door Time</strong>
				</div>
			
				<ion-item>
					<div tabindex="0"></div>
					<ion-label>Doors Open</ion-label>
					<ion-datetime display-format="h:mm A" minute-values="0,15,30,45" placeholder="Select Time" v-model="concertTime"></ion-datetime>
				</ion-item>   
			</div>
		</div>
		<!-- 
		Venue
		-->
		<div v-if="view == 'venue'">
			<div class="padding-top-md padding-left-md">
				<div>
					<strong>Venue</strong>
				</div>
			</div>

			<div v-if="venue != null && venue != undefined" class="padding-top-sm padding-left-md">
				{{venue.name}} - {{venue.city}} {{venue.state}}
				<div class="padding-right-md padding-top-sm">
					<ion-button color="light" size="small" class="w-100" @click.prevent="venue = null">Edit Venue</ion-button>
				</div>
			</div>
			<div v-if="venue === null || venue === []">
				<div v-if="venues">
					<VenueSearch :venues="venues" @get-the-venue="getVenue" />
				</div>
			</div>
		</div>
		<!-- 
		Title
		-->
		<div v-if="view == 'general'">
			<!-- Title -->
			<div class="padding-top-md padding-left-md">
				<strong>Title</strong>
			</div>
			<!-- Title Input -->
			<div>
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-input v-model="title" placeholder="Enter a title"></ion-input>
				</ion-item>
			</div>
			<!-- Details -->
			<div class="padding-top-md padding-left-md">
				<strong>Details</strong>
			</div>
			<!-- Details Input -->
			<div>
				<TextWrapper 
				:store="store.state"
				:post-body="bodyText"
				input-type="textarea"
				:is-mentions="true" 
				:is-hashtags="true" 
				:has-button="false" 
				:set-delimeter="null"
				@input-data="getInputData" />
			</div>
			<div class="padding-top-md padding-left-md">
				<strong>Hosts</strong>
			</div>
			<div>
				<!-- Hosts -->
				<PageSearch @get-the-page="getPage" /> 
				<div v-if="pages.length > 0">
					<ion-list style="width:100%;">
						<ion-item v-for="(page,index) in pages" :key="page">
							<div tabindex="0"></div>
							<ion-label class="text-cap">{{page.name}}</ion-label>
							<ion-icon slot="end" color="danger" :icon="trash" @click="removePage(index)" />
						</ion-item>
					</ion-list>
				</div>       
			</div>

		</div>

		<!--
		Images
		-->
		<div v-if="view == 'images'">
			<!-- Image -->
			<div v-if="imageUrl != null">
				<ion-img :src="imageUrl" />
			</div>
			<div v-else class="margin-md">
				<div style="height:140px;" class="bg-light w-100">
					&nbsp;
				</div>
			</div>
			<!-- Main image -->
			<div class="padding-left-md padding-right-md padding-bottom-md border-bottom">
				<div>
					<strong>Cover Image</strong>
					<p>This is the full size rectangular image. This image will be used as a default.</p>
				</div>
				<ion-button v-if="imageUrl === null" class="w-100" size="small" @click="takePicture(1)">
					Upload Cover Image
				</ion-button>

				<ion-button v-if="imageUrl != null" slot="start" size="small" color="danger" @click="imageUrl = null">
					<ion-icon :icon="trash"></ion-icon>
				</ion-button>  
			</div>
			<!-- Secondary image -->
			<div class="padding-left-md padding-right-md">
				<!-- Secondary Image -->
				<div v-if="secondaryImageUrl != null">
					<ion-img :src="secondaryImageUrl" />
				</div>
				<div v-else class="padding-top-md padding-bottom-md">
					<div style="height:140px;width:140px;margin:0 auto;" class="bg-light">
						&nbsp;
					</div>
				</div>
				<div>
					<strong>Thumbnail Image</strong>
					<p>This is a square image.</p>
				</div>
				<ion-button v-if="secondaryImageUrl === null" class="w-100" size="small" @click="takePicture(2)">
					Upload Thumbnail Image
				</ion-button>
				<ion-button v-if="secondaryImageUrl != null" slot="start" size="small" color="danger" @click="secondaryImageUrl = null">
					<ion-icon :icon="trash"></ion-icon>
				</ion-button>  
			</div>

		</div>
		<!--
		Artists
		-->
		<div v-if="view == 'artists'">
			<ArtistSearch @get-the-artist="getArtist" />
			<!-- Added Artists -->
			<div v-if="artists.length > 0">
				<ion-list style="width:100%;">
					<ion-item v-for="(artist,index) in artists" :key="artist">
						<ion-label class="text-cap">{{artist.name}}</ion-label>
						<ion-icon slot="end" color="danger" :icon="trash" @click="removeArtist(index)" />
					</ion-item>
				</ion-list>
			</div>
		</div>
		<!--
		Tickets
		-->

		<div v-if="view == 'tickets'">
			<TicketForm @get-ticket="getTicket" />
			<!-- Title -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="ticketLink" placeholder="Ticket link"></ion-input>
			</ion-item>
			<!-- Added Tickets -->
			<div v-if="tickets.length > 0">
				<div v-for="ticket in tickets" :key="ticket" style="border-bottom: 1px solid #eeeeee;">
					<ion-row>
						<ion-col size="10">
							{{ticket.label}} 
						</ion-col>
						<ion-col size="2">
							{{ticket.price}}
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<ion-button v-if="ticket.status === 1" size="small" color="success" @click.prevent="updateTicketStatus(ticket.id, 0)">On Sale</ion-button>
							<ion-button v-else size="small" color="light" @click.prevent="updateTicketStatus(ticket.id, 1)">Off Sale</ion-button>
						</ion-col>
					</ion-row>
				</div>
			</div>
		</div> 
		<!--
		Genres
		-->
		<div v-if="view == 'genres'">
			<GenreSearch @get-the-genre="getGenre" />
			<!-- Added Tickets -->
			<div v-if="genres.length > 0">
				<ion-list style="width:100%;">
					<ion-item v-for="(genre,index) in genres" :key="genre">
						<div tabindex="0"></div>
						<ion-label class="text-cap">{{genre.name}}</ion-label>
						<ion-icon slot="end" color="danger" :icon="trash" @click="removeGenre(index)" />
					</ion-item>
				</ion-list>
			</div>
		</div>
		<!--
		Booking
		-->
		<div v-if="view == 'booking'">
			<BookingForm :stat="status" :details="bookingDetails" @get-booking="getBooking" />
		</div>
		<!--
			Preview
		-->
		<div v-if="isValidated > 0">
			<ion-button v-if="view != 'preview'" @click.prevent="view = 'preview'">
				Preview & Save
			</ion-button>
			<div v-if="view === 'preview'">
				<!-- Image -->
				<div v-if="imageUrl != null">
					<ion-img :src="imageUrl" />
				</div>
				<!-- Date & Title -->
				<div class="padding-top-md padding-left-md padding-right-md">
					<p>{{humanDate}}</p>
					<strong>{{title}}</strong>
				</div>
				<!-- Tickets -->
				<div v-if="tickets.length > 0" class="padding-top-md padding-left-md padding-right-md">
					<strong>Tickets</strong>
					<ion-list style="width:100%;">
						<ion-item v-for="(ticket,index) in tickets" :key="ticket">
							<div tabindex="0"></div>
							<ion-label class="text-cap">
								{{ticket.type.replace('-', ' ')}} <span v-if="ticket.price != null">${{ticket.price}}</span>
							</ion-label>
							<ion-icon slot="end" color="danger" :icon="trash" @click="removeTicket(index)" />
						</ion-item>
					</ion-list>
				</div>
				<!-- Ticket URL -->
				<div v-if="ticketLink != null && ticketLink != ''" class="padding-top-md padding-left-md padding-right-md">
					<ion-icon :icon="link" /> {{ticketLink}}
				</div>
				<!-- Venue -->
				<div v-if="venue != null && venue != undefined" class="padding-top-md padding-left-md">
					<strong>Venue</strong>
					<p>{{venue.name}} - {{venue.city}} {{venue.state}}</p>
				</div>
				<!-- Artists -->
				<div v-if="artists.length > 0">
					<div class="padding-right-md-md padding-left-md">
						<strong>Artists</strong>
					</div>


					<ion-list style="width:100%;">
						<ion-item v-for="(artist,index) in artists" :key="artist">
							<div tabindex="0"></div>
							<ion-label class="text-cap">{{artist.name}}</ion-label>
							<ion-icon slot="end" color="danger" :icon="trash" @click="removeArtist(index)" />
						</ion-item>
					</ion-list>

				</div>

				<!-- Genres -->
				<div v-if="genres.length > 0">
					<ion-chip v-for="genre in genres" :key="genre">
						{{genre.name}}
					</ion-chip>
				</div>

				<!-- Details -->
				<div v-if="bodyText != null && bodyText != ''" class="padding-left-md padding-right-md">
					<strong>Details</strong>
					<div>
						{{bodyText}}
					</div>
				</div>
				<!--Booking -->
				<div v-if="bookingDetails != null && bookingDetails != ''" class="padding-top-sm padding-left-md">
					<strong>Booking Details</strong>
					<div>
						{{bookingDetails}}
					</div>
				</div>
				<div v-if="pages" class="padding-bottom-sm">
					<div class="padding-top-md padding-right-md padding-left-md">
						<strong>Hosts</strong>
					</div>
					<div v-for="page in pages" :key="page">
						<div v-if="page.role_id === 4">
							<ion-item>
								<div tabindex="0"></div>
								<ion-avatar>
									<img v-if="page.image" :src="page.image" />
									<img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
								</ion-avatar>
								<ion-label class="margin-left-sm text-capitalize">
									{{page.name}}
								</ion-label>
							</ion-item>
						</div>
					</div>
				</div>
				<!--Status -->
				<div class="padding-top-sm padding-left-md">
					Posted to: 
					<span v-if="status === 0">Not published</span>
					<span v-if="status === 1">Concert calendar</span>
					<span v-if="status === 2">Concert calendar & booking feed</span>
					<span v-if="status === 3">Booking feed</span>
				</div>
			</div>

			<div v-if="isValidated === 1 || isValidated === 2">
				<ion-item v-if="view == 'preview'">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-button @click.prevent="updateConcert()">
						Save
					</ion-button>
				</ion-item>
			</div>
		</div>
	</div>
</template>

<script>
import { IonSegment, IonSegmentButton, IonLabel, IonItem, IonDatetime, IonButton, IonInput, IonList, IonIcon, IonImg, IonChip, IonAvatar, toastController, loadingController, IonRow, IonCol } from '@ionic/vue';
import { close, trash, camera, link } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from 'vue';
import apiClient from '../services/api';
import TextWrapper from './TextWrapper.vue';
import VenueSearch from './VenueSearch.vue';
import ArtistSearch from './ArtistSearch.vue';
import PageSearch from './PageSearch.vue';
import GenreSearch from './GenreSearch.vue';
import TicketForm from './TicketForm.vue';
import BookingForm from './BookingForm.vue';
import { useStore } from "vuex";
import { format } from 'date-fns'
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";

export default defineComponent( {
	components: { IonSegment, IonSegmentButton, IonLabel, IonItem, IonDatetime, IonButton, VenueSearch, PageSearch, TextWrapper, IonInput, IonList, IonIcon, IonImg, IonChip, IonAvatar, ArtistSearch, TicketForm, GenreSearch, BookingForm, IonRow, IonCol },
	name: 'ManageConcert',
	props: ['post'],
	setup(props) {
		const store = useStore();
		const view = ref('date');
		const timeout = { default: 6000 }
		const toastMessage = ref(null);
		const postId = ref(0);
		const concertDate = ref(null);
		const concertTime = ref(null);
		const venues = ref([]);
		const venue = ref(null);
		const venueId = ref(0);
		const title = ref(null);
		const bodyText = ref(null);
		const linkData = ref(null);
		const pages = ref([]);
		const imageUrl = ref(null);
		const secondaryImageUrl = ref(null);
		const artists = ref([]);
		const genres = ref([]);
		const tickets = ref([]);
		const ticketLink = ref(null);
		const bookingDetails = ref(null);
		const status = ref(0);
		const isValidated = ref(0);
		const humanDate = ref(null);
		const onSaleTickets = ref([]);

		onMounted(() => {
			validateConcert()
			getVenues()
			if(props.post) {
				// General
				postId.value = props.post.id
				title.value = props.post.title
				bodyText.value = props.post.body
				status.value = props.post.status
				// Event
				if(props.post.event) {
					// Event basic
					concertDate.value = props.post.event.date_time
					concertTime.value = props.post.event.date_time
					ticketLink.value = props.post.event.ticket_link
					venueId.value = props.post.event.venue_id
					bookingDetails.value = props.post.event.booking_details
					// Secondary Image
					if(props.post.event.secondary_image != null && props.post.event.secondary_image != '') {
						secondaryImageUrl.value = props.post.event.secondary_image
					}
					// Venue
					if(props.post.event.venue) {
						venue.value = props.post.event.venue
					}
				}
				// Pages
				if(props.post.pages) {
					for(var i=0;i<props.post.pages.length;i++) {
						if(props.post.pages[i].role_id === 4) {
							pages.value.push(props.post.pages[i])
						}
					}
				}
				// Artists
				if(props.post.pages) {
					for(var x=0;x<props.post.pages.length;x++) {
						if(props.post.pages[x].role_id === 3) {
							artists.value.push(props.post.pages[x])
						}
					}
				}
				// Image
				if(props.post.filename != null && props.post.filename != '') {
					imageUrl.value = props.post.filename
				}
				// Tickets
				if(props.post.tickets) {
					tickets.value = props.post.tickets
				}
				// Genres 
				if(props.post.genres) {
					genres.value = props.post.genres
				}
			}
		})

		function getVenues() {
			apiClient.get('/api/get/all-venues',
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				venues.value = response.data
			}).catch(error => {
				console.log(error)
			}); 
		}

		function getInputData(e) {
			if(e) {
				bodyText.value = e.text
				linkData.value = e.linkData
			}
		}

		function getPage(e) {
			if(e) {
				pages.value.push(e)
			}
		}

		function removePage(i) {
			pages.value.splice(i, 1)
		}

		function removeArtist(i) {
			artists.value.splice(i, 1)
		}

		function removeTicket(i) {
			tickets.value.splice(i, 1)
		}

		function removeGenre(i) {
			genres.value.splice(i, 1)
		}

		async function takePicture(imgNum) {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					//console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					if(imgNum === 1) {
						imageUrl.value = image.dataUrl;
					}
					if(imgNum === 2) {
						secondaryImageUrl.value = image.dataUrl;
					}          
					// can be set to the src of an image now
					//console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function updateConcert() {
			let combinedPages = []

			if(pages.value) {
				for(var i=0;i<pages.value.length;i++) {
					combinedPages.push(pages.value[i])
				}
			}
			if(artists.value) {
				for(var x=0;x<artists.value.length;x++) {
					combinedPages.push(artists.value[x])
				}
			}
			presentLoading()
			apiClient.post('/api/update/concert', 
			{
				post_id: postId.value,
				title: title.value,  
				body: bodyText.value, 
				status: status.value, 
				post_type: 'concert',
				image: imageUrl.value,
				category: 'concert',
				date_time: concertDate.value, 
				venue_id: venue.value.id,
				tickets: tickets.value,
				genres: genres.value,   
				booking_details: bookingDetails.value,
				pages: combinedPages,
				ticket_link: ticketLink.value,
				secondary_image: secondaryImageUrl.value,
				scene_id: venue.value.scene_id
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'Your post has been updated.'
					openToast()
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
       
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in to update your profile.'
					openToast()
				}
			}).catch(error => {
				console.log(error)
			});      
		}

		function getArtist(e) {
			if(e) {
				artists.value.push(e)
			}
			if(artists.value) {
				for (var i=0;i<artists.value.length;i++) {
					if(artists.value[i].genres) {
						for (var x=0;x<artists.value[i].genres.length;x++) {
							if(isGenreUnique(artists.value[i].genres.value[x]) === true) {
								genres.value.push(artists.value[i].genres[x])
							}
						}
					}
				}
			}
			validateConcert()
		}

		function isGenreUnique(g) {
			let unique = true
				if(genres.value) {
					for(var i=0; i<genres.value.length;i++) {
						if(genres.value[i].id === g.id) {
							unique = false
						}
					}
				}
			return unique
		}

		function getVenue(e) {
			if(e) {
				venue.value = e
			}
		}

		function getTicket(e) {
			if(e) {
				tickets.value.push(e)
			}
			validateConcert()
		}

		function getGenre(e) {
			if(e) {
				genres.value.push(e)
			}
			validateConcert()
		}

		function getBooking(e) {
			if(e) {
				status.value = e.status
				bookingDetails.value = e.bookingDetails
			}
			validateConcert()
		}

		function validateConcert() {
			let isDate = false
			let isTime = false
			let isVenue = false
			let isTitle = false
			let isGenres = false
			let isTickets = false
			// Date
			if(concertDate.value) {
				humanDate.value = format(Date.parse(concertDate.value), 'eeee, MMMM dd yyyy | h:MM')
				isDate = true
			}
			// Time
			if(concertTime.value) {
				isTime = true
			}
			// Venue
			if(venue.value) {
				isVenue = true
			}
			// Title
			if(title.value != null && title.value != '') {
				isTitle = true
			}
			// Genres
			if(genres.value) {
				isGenres = true
			} 
			// Tickets
			if(tickets.value) {
				isTickets = true
			} 

			// Fully validated for public calendar
			if(isDate === true && isTime === true && isVenue === true && isTitle === true && isGenres === true && isTickets === true) {
				isValidated.value = 1
			} else {
				// Booking Only
				if(isDate === true && isTime === true && isVenue === true && isTitle === true) {
					isValidated.value = 2
				} 
				// Not validated
				else {
					isValidated.value = 0
				}
			}
		}

		function doOnSaleTickets() {
			onSaleTickets.value = []
			for(var i=0;i<tickets.value.length;i++) {
				
				onSaleTickets.value.push({
					id: tickets.value[i].id,
					status: tickets.value[i].status
				})
				
			}
		}

		function updateTicketStatus(id, status) {
			for(var i=0;i<tickets.value.length;i++) {
				if(tickets.value[i].id === id) {
					storeTicketStatus(id, status)
					tickets.value[i].status = status
				}
			}
		}

		function storeTicketStatus(id, status) {
			presentLoading()
			apiClient.post('/api/update/ticket-status', 
			{
				post_id: postId.value,
				ticket_id: id,
				status: status 
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'Your ticket status has been updated.'
					openToast()
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
       
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in to update your profile.'
					openToast()
				}
			}).catch(error => {
				console.log(error)
			});
		}

		return { view, concertDate, concertTime, venues, venue, store, title, getInputData, getPage, pages, bodyText, removePage, close, trash, camera, link, imageUrl, secondaryImageUrl, takePicture, updateConcert, artists, removeArtist, removeGenre, getArtist, genres, tickets, ticketLink, removeTicket, getTicket, bookingDetails, status, getBooking, getGenre, isValidated, validateConcert, postId, getVenue, humanDate, venueId, doOnSaleTickets, onSaleTickets, updateTicketStatus }
	},
	watch: {
		concertDate: function() {
			this.validateConcert()
		},
		concertTime: function() {
			this.validateConcert()
		},
		title: function() {
			this.validateConcert()
		},
		tickets: {
			handler() {
				this.doOnSaleTickets()
			},
			deep:true
		}
	}
});
</script>