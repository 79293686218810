<template>
  <ion-page>
    <!-- Header -->
    <ion-header>
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click.prevent="updatePost = true">
            Save
          </ion-button>
        </ion-buttons>
        <ion-title>{{slug}}</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content class="bg-white">
      <!--  -->
      <div class="app-max-width bottom-padding">
        <div v-if="post">
          <!-- Can edit -->
          <div v-if="canUserEdit() === true">
            <!-- Post / Article -->
            <div v-if="post.post_type == 'post' || post.post_type == 'article'">
              <ManageArticle :post="post" :update="updatePost"  @reset-update="resetUpdate" />
            </div>
            <!-- Concert -->
            <div v-if="post.post_type == 'concert'">
              <ManageConcert :post="post" />
            </div>
            <!-- Classified -->
            <div v-if="post.post_type == 'classified'">
              <ManageClassified :post="post" :update="updatePost"  @reset-update="resetUpdate" />
            </div>
          </div>
          <!-- Can not edit -->
          <div v-else class="padding-top-md padding-left-md padding-right-md">
            You are not authorized to edit this post.
          </div>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, loadingController, toastController } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark, close, camera, trash } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import ManageConcert from '../../components/ManageConcert.vue';
import ManageClassified from '../../components/ManageClassified.vue';
import ManageArticle from '../../components/ManageArticle.vue';


export default defineComponent({
  name: 'Genre',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, ManageConcert, ManageClassified, ManageArticle
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const slug = ref(null);
    const post = ref(null);
    const toastMessage = ref(null);
    const timeout = { default: 6000 }
    const updatePost = ref(false);

    onMounted(() => {
      slug.value = router.currentRoute._rawValue.params.slug
      getPost()
    })

    function getPost() {
      presentLoading()

      apiClient.get('/api/get/post-for-update-by-slug/'+slug.value, 
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          post.value = response.data
        }).catch(error => {
          console.log(error)
          toastMessage.value = 'Something went wrong'
          openToast()
        });  
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        }]
      })
      return toast.present();
    }

    function canUserEdit() {
      let isAuthorized = false
      if(authUser.value) {
        if(post.value) {
          if(authUser.value.id === post.value.user_id) {
            isAuthorized = true
          }
        }
      }
      return isAuthorized
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    function resetUpdate(e) {
      updatePost.value = e
    }

    return {
      chevronBack, add, reorderThree, checkmark, router, updateRoute, goBack, authUser, slug, post, canUserEdit, close, camera, trash, store, updatePost, resetUpdate  
    }
  },

});
</script>

<style scoped>

</style>